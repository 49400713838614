/**
 * A function to make the document.createElement creation easier
 * @constructor
 * @param {element} element - The element to be created
 * @param {obj} obj - The attributes to be used in the element
 * @param {html} html - The innerHtml attribute if needed
 */
window.elementCreate = elementCreate;
function elementCreate(element, obj, html) {
  // creating the element
  var element = document.createElement(element);

  if (obj !== undefined || '') {
    var i;
    for (i in obj) {
      element.setAttribute(i, obj[i]);
    }
  }

  if (html !== undefined) {
    element.innerHTML = html;
  }

  // returning the variable
  return element;
}

/**
 * A function to make the appendingChild feature a little quicker and tidier
 * apc - append child
 * @constructor
 * @param {parent} parent - The parent element
 * @param {child} child - The Child Element
 */
window.apc = apc;
function apc(parent, child) {
  parent.appendChild(child);
}

/**
 * A function to pass append to parent
 * mapc - mass append child
 * @param  {string} parent   The parent element
 * @param  {obj} childObj    The child element(s)
 */
window.mapc = mapc;
function mapc(parent, childObj) {
  for (var i in childObj) {
    parent.appendChild(childObj[i]);
  }
}
